<template>
  <Banner bg-image="green-bg.jpg" talk-img="suicide-thoughts.png">
    <template v-slot:title>Help for suicidal thoughts</template>
    <template v-slot:subtitle>
      Having thoughts of suicide is a common occurrence among young people; it
      is not unusual or something to be ashamed of.
    </template>
    <template v-slot:description>
      Let’s learn about thoughts and look at some coping strategies.</template
    >
  </Banner>

  <section class="life-sect">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="mb-6 text-2xl font-bold call-text md:text-3xl text-blue">
        Life can feel overwhelming sometimes
      </div>
      <p class="">
        There are many things that can impact your mental health and well-being.
        School and home stress, trauma, dealing with harassment and bullying,
        going through a breakup or experiencing a loss are some examples of
        things that can make you feel overloaded or defeated.
      </p>
      <p class="my-6">
        You may feel unsure of what to do next, sad, hopeless, ashamed,
        unsupported, like you have no one to talk to or your usual coping
        strategies are not working for you. As a result, your pain may start to
        feel unbearable and when your feel this way, you may have thoughts of
        suicide.
      </p>
      <p class="text-blue">It is important to know that help is available.</p>
    </div>
  </section>

  <section class="my-8 thoughts-sect md:my-12 pt-14">
    <div class="max-w-6xl px-4 mx-auto text-center sm:px-6">
      <div class="mb-6 text-2xl font-bold call-text md:text-3xl text-blue">
        Learn to regulate your thoughts with calming exercises
      </div>
      <p class="w-full mx-auto md:w-3/4">
        If you’re having thoughts about suicide right now, here are some
        exercises to help you ground yourself and calm down so you are able to
        think more clearly.
      </p>

      <div
        class="grid grid-cols-1 mt-10 md:grid-cols-2 gap-y-8 gap-x-6 icon-btns"
      >
        <router-link :to="{ name: 'PracticeGuidedMeditation' }">
          <QuickAccess
            arrow="text-green"
            text="Practice guided meditation"
            img="meditation.svg"
          />
        </router-link>
        <router-link :to="{ name: 'PracticeBreathingExercises' }">
          <QuickAccess
            arrow="text-red"
            text="Practice breathing exercises"
            img="exercises.svg"
          />
        </router-link>
      </div>
    </div>
  </section>

  <!-- Cope Section -->
  <section class="pb-2 my-8 mt-14 pt-14">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="mb-6 text-2xl font-bold md:text-3xl text-blue">
        How to cope with thoughts of suicide
      </div>
      <p class="">
        There is no single way or “right” way to cope with thoughts of suicide
        or ask for help. People will experience thoughts of suicide differently
        and therefore, coping with these thoughts will be different for every
        individual. If you are having suicidal thoughts, it can be distressing,
        isolating, uncomfortable and overwhelming. It’s important to get help
        when you start to notice yourself having these thoughts. With the right
        support and coping strategies, it’s possible to overcome thoughts and
        feelings related to suicide.
      </p>

      <p class="my-6">
        The following strategies for coping with suicidal thoughts are
        suggestions that have resonated with young people we spoke to. It is not
        intended to be an exhaustive list. Try out some of the suggestions to
        see what works best for you.
      </p>
      <div class="my-6 text-2xl font-bold md:text-3xl text-blue">
        If you are thinking about suicide, here are a few ideas to help you
        cope:
      </div>
    </div>
  </section>
  <!-- Thinking about suicide section -->
  <section class="px-2 mx-auto max-w-6xl sm:px-1.5">
    <div class="grid grid-cols-1 gap-6">
      <!-- 1 -->
      <div class="thought-block">
        <div class="no">1</div>

        <div class="items-end justify-between p-4 md:flex lg:p-6 gap-x-8">
          <div class="w-full md:w-4/6">
            <div class="mb-2 text-xl font-bold">Name your emotions</div>
            <p>
              It is important to notice how you are feeling, knowing what
              emotions you are feeling can help us talk about feelings more
              clearly, and calm ourselves down.
            </p>
          </div>
          <div class="w-full md:w-2/6 md:text-right">
            <GreenBtn
              :width-full="true"
              name="NameYourEmotions"
              text="Learn how to name emotions"
            />
          </div>
        </div>
      </div>
      <!-- 2 -->
      <div class="thought-block">
        <div class="no">2</div>

        <div class="items-end justify-between p-4 md:flex lg:p-6 gap-x-8">
          <div class="w-full md:w-4/6">
            <div class="mb-2 text-xl font-bold">
              Practice Indigenous cultural healing practices
            </div>
            <p>
              Ceremonies and other cultural practices play an important role in
              the overall wellbeing of Indigenous people<ReadMore>
                Through participation in songs, prayer, music, drumming and
                dance the family and community contribute to your healing energy
                of individuals.
              </ReadMore>
            </p>
          </div>
          <div class="w-full md:w-2/6 md:text-right">
            <GreenBtn
              :width-full="true"
              name="ExploreCulturalHealingPractices"
              text="Learn about cultural healing practices"
            />
          </div>
        </div>
      </div>
      <!-- 3 -->
      <div class="thought-block">
        <div class="no">3</div>

        <div class="items-end justify-between p-4 md:flex lg:p-6 gap-x-8">
          <div class="w-full md:w-4/6">
            <div class="mb-2 text-xl font-bold">
              Practice guided meditation and mindfulness
            </div>
            <p>
              You might feel hopeless, worthless, or angry, meditation can make
              it easier to pay attention to what<ReadMore>
                you are feeling or experiencing in your body as they come up.
                So, if you begin experiencing negative thought patterns or you
                are less interest in the things you usually like to do, this may
                help you focus on self-care.
              </ReadMore>
            </p>
          </div>
          <div class="w-full md:w-2/6 md:text-right">
            <GreenBtn
              :width-full="true"
              name="PracticeGuidedMeditation"
              text="Try guided meditation"
            />
          </div>
        </div>
      </div>
      <!-- 4 -->
      <div class="thought-block">
        <div class="no">4</div>

        <div class="items-end justify-between p-4 md:flex lg:p-6 gap-x-8">
          <div class="w-full md:w-4/6">
            <div class="mb-2 text-xl font-bold">
              Spend time with someone you care about
            </div>
            <p>
              Telling somebody that you are having suicidal thoughts can be
              difficult, as suicide is often stigmatized. If you are<ReadMore>
                having suicidal thoughts, talking about how you feel can help. a
                number of people can support you, depending on your need or what
                you are looking for. Consider talking to an elder, therapist,
                friend, family member or teacher.
              </ReadMore>
            </p>
          </div>
          <div class="self-end w-full md:w-2/6 md:text-right">
            <GreenBtn
              :width-full="true"
              name="FindSomeoneToTalkTo"
              text="Find Someone to Talk To"
            />
          </div>
        </div>
      </div>
      <!-- 5 -->
      <div class="thought-block">
        <div class="no">5</div>

        <div class="items-end justify-between p-4 md:flex lg:p-6 gap-x-8">
          <div class="w-full md:w-4/6">
            <div class="mb-2 text-xl font-bold">Try grounding techniques</div>
            <p>
              Grounding techniques may help distract you from what you are
              experiencing and help you refocus. It can<ReadMore>
                be helpful to have a selection of grounding exercises that you
                can draw upon.
              </ReadMore>
            </p>
          </div>
          <div class="w-full md:w-2/6 md:text-right">
            <GreenBtn
              :width-full="true"
              name="PracticeGroundingTechniques"
              text="Learn grounding techniques"
            />
          </div>
        </div>
      </div>
      <!-- 6 -->
      <div class="thought-block">
        <div class="no">6</div>

        <div
          class="items-end justify-between flex-grow p-4 md:flex lg:p-6 gap-x-8"
        >
          <div class="w-full md:w-4/6">
            <div class="mb-2 text-xl font-bold">Try breathing exercises</div>
            <p>
              Breathing exercises can help you feel more grounded and calm down.
            </p>
          </div>
          <div class="w-full md:w-2/6 md:text-right">
            <GreenBtn
              :width-full="true"
              name="PracticeBreathingExercises"
              text="Try breathing exercises"
            />
          </div>
        </div>
      </div>
      <!-- 7 -->
      <div class="thought-block">
        <div class="no">7</div>

        <div class="items-end justify-between p-4 md:flex lg:p-6 gap-x-8">
          <div class="w-full md:w-4/6">
            <div class="mb-2 text-xl font-bold">Develop a safety plan</div>
            <p>
              Create a safety plan to help you cope and find support if you’re
              feeling overwhelmed.
            </p>
          </div>
          <div class="w-full md:w-2/6 md:text-right">
            <GreenBtn
              :width-full="true"
              name="CreateASafetyPlan"
              text="Create a safety plan"
            />
          </div>
        </div>
      </div>
      <!-- 8 -->
      <div class="thought-block">
        <div class="no">8</div>

        <div class="items-end justify-between p-4 md:flex lg:p-6 gap-x-8">
          <div class="w-full md:w-4/6">
            <div class="mb-2 text-xl font-bold">
              Watch & listen to videos, music and more
            </div>
            <p>
              Learn from people telling their stories of lived experience
              related to mental health and suicide.
            </p>
          </div>
          <div class="w-full md:w-2/6 md:text-right">
            <GreenBtn
              :width-full="true"
              name="WatchVideosAndPodcasts"
              text="Visit our multimedia library"
            />
          </div>
        </div>
      </div>
      <!-- 9 -->
      <div class="thought-block">
        <div class="no">9</div>

        <div class="items-end justify-between p-4 md:flex lg:p-6 gap-x-8">
          <div class="w-full md:w-4/6">
            <div class="mb-2 text-xl font-bold">
              Attend a youth support group
            </div>
            <p>
              Many young people experience the same thoughts you are having.
              Attending a support group can help you feel like you’re not alone.
            </p>
          </div>
          <div class="w-full md:w-2/6 md:text-right">
            <GreenBtn
              :width-full="true"
              name="FindASupportGroup"
              text="Find a support group"
            />
          </div>
        </div>
      </div>
      <!-- 10 -->
      <div class="thought-block">
        <div class="no">10</div>

        <div class="items-end justify-between p-4 md:flex lg:p-6 gap-x-8">
          <div class="w-full md:w-4/6">
            <div class="mb-2 text-xl font-bold">Create a hope box</div>
            <p>
              A “hope box” is personal to you and should be filled with items
              that can help you feel better or hopeful when<ReadMore>
                you are down. You can include anything from your favorite
                stuffed animal to a puzzle to help keep you distracted.
              </ReadMore>
            </p>
          </div>
          <div class="w-full md:w-2/6 md:text-right">
            <GreenBtn
              :width-full="true"
              name="MakeAHopeBox"
              text="How to make a hope box"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <FooterCard img="stay-safe.png" text="Intent" name="Intent">
    <template v-slot:header> Stay safe and learn more </template>
    <template v-slot:content>
      To provide the most appropriate support and stay safe you can learn more
      about suicide intent or learn how to get help when having a plan for
      suicide.
    </template>
    <template v-slot:btn>
      <GreenBtn name="PlanningSuicide" text="Plan" />
    </template>
  </FooterCard>
</template>

<script>
import Banner from "@/components/Banner.vue";
import QuickAccess from "@/components/QuickAccess.vue";
import GreenBtn from "@/components/GreenBtn.vue";
import ReadMore from "@/components/ReadMore.vue";
import FooterCard from "@/components/FooterCard.vue";
export default {
  name: "Thoughts",
  components: {
    Banner,
    QuickAccess,
    GreenBtn,
    ReadMore,
    FooterCard,
  },
};
</script>

<style scoped>
.thoughts-sect {
  background-color: rgba(60, 94, 170, 0.06);
}
.thoughts-sect .icon-btns {
  bottom: -50px;
  position: relative;
}

@media (max-width: 640px) {
  .thought-block {
    display: flex !important;
    flex-direction: column !important;
    padding-left: 0 !important;
    margin-bottom: 2rem !important;
  }

  .thought-block .no {
    position: relative !important;
    justify-content: center !important;
    transform: translate(0, -50%) !important;
  }
}
.thought-block {
  display: flex;
  align-items: center;
  border: 5px solid #3c5eaa;
  border-radius: 10px;
  background-color: #f8f8f8;
  padding-left: 130px;
  position: relative;
}
.thought-block .no {
  background: #3c5eaa;
  color: #fff;
  font-weight: bold;
  font-size: 60px;
  width: 120px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -2px;
  left: 0;
  bottom: 0;
}
</style>
